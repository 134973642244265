<template>
  <div class="appointmentList-view">
    <div class="flex-a-c">
      <el-input
            type="text"
            placeholder="请输入管理员姓名"
            v-model="keyword"
            style="width: 240px"
            show-word-limit>
      </el-input>
      <el-button
        class="v-primary ml10"
        type="primary"
        @click="getPageDepartment"
        >搜索</el-button
      >
      <el-button
        class="v-primary ml10"
        type="primary"
        @click="editDepartment('add')"
        >新增管理员</el-button
      >

      <el-button
              class="v-primary ml10"
              type="primary"
              @click="changeBoss"
      >更换BOSS</el-button>
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="name" align="center" label="姓名"/>
        <el-table-column prop="departmentName" width="250" align="center" label="负责部门" />
        <el-table-column prop="state" align="center" label="权限">
          <template slot-scope="scope">
            <div class="flex-c-c">

              <!-- <div class="cblue hover_pointer" @click="checkAll(scope.row)" v-if="scope.row.member">
                  点击查看
              </div>
              <p class="cblue" v-else>无</p> -->
              <div class="cblue hover_pointer" @click="checkAll(scope.row)">
                  点击查看
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="empStatus" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.empStatus">
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column prop="state" width="270" align="center" label="操作">
          <template slot-scope="scope"
            ><span
              style="color: $color-1; cursor: pointer;margin-right:10px"
              @click="editDepartment('edit',scope.row)"
            >
              编辑
            </span>
            <span
              style="color: #FD634E; cursor: pointer;margin-right:10px"
              @click="dissolve(scope.row.empId)"
            >
              删除
            </span>

          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!-- 查看所有成员 -->
    <el-dialog
          class="label_dialog"
          title="查看权限"
          width="30%"
          center
          :visible.sync="checkVisible"
          :modal-append-to-body="false"
          @closed="checkVisible = false"
        >
        <div class="" v-if="memberData">
          <el-tree :data="treeData"
                    ref="tree"
                    show-checkbox
                    :props="defaultProps"
                    node-key="id"></el-tree>
          <div class="d-flex justify-content-center">
            <el-button
              class="v-primary ml10"
              @click="checkVisible = false"
              type="primary"
              >确认</el-button
            >
          </div>
        </div>
    </el-dialog>
    <!--身份验证-->
    <el-dialog class="label_dialog" title="身份验证"  width="360px"
            center
            :visible.sync="bossCertificationVisible"
            :modal-append-to-body="false"
            @closed="bossCertificationVisible = false"
    >
      <el-form :model="certificationForm"  ref="loginRef" style="width: 300px;">

        <el-form-item
                style="margin-top: 20px"
                id="border-none" >
          <el-input
                  v-model="certificationForm.phone"
                  size="large"
                  style="width: 300px; border: none"
                  placeholder="请输入BOSS手机号"
                  prefix-icon="el-icon-mobile"
          ></el-input>
        </el-form-item>
        <el-form-item
                style="margin-top: 20px"
                prop="password"
                id="border-none"
        >
          <div class="flex-a-b-c">
            <el-input
                    type="password"
                    style="position: fixed; bottom: -9999px"
            />
            <el-input
                    v-model="certificationForm.smsCode"
                    auto-complete="new-accounts"
                    size="large"
                    style="width: 160px; border: none;"
                    placeholder="请输入验证码"
                    prefix-icon="el-icon-lock"
            ></el-input>
            <el-button
                    plain
                    type="primary"
                    :disabled="countDown !== 0 ? true : false"
                    size="large"
                    @click="getSendCode(certificationForm.phone)"
            >{{
              countDown ? `重新发送(${countDown})` : "获取验证码"
              }}</el-button
            >
          </div>
        </el-form-item>

        <el-form-item style="margin-top: 30px">
          <el-button
                  plain
                  type="primary"
                  style="width: 48%"
                  size="large"
                  @click="bossCertificationVisible = false"
          >取消</el-button
          >
          <el-button
                  type="primary"
                  style="width: 48%"
                  size="large"
                  @click="certificationphone"
          >验证</el-button
          >
        </el-form-item>
      </el-form>

    </el-dialog>

    <!--员工-->
    <el-dialog
            class="label_dialog"
            title="员工列表"
            width="20%"
            center
            :visible.sync="administratorVisible"
            :modal-append-to-body="false"
            @closed="checkVisible = false"
    >
      <div class="">
        <div class="mb20 d-flex">
          <el-input
                  type="text"
                  placeholder="请输入员工员姓名"
                  v-model="administratorKey"
                  style="width: 240px"
                  show-word-limit>
          </el-input>
          <el-button
                  class="v-primary ml10"
                  type="primary"
                  @click="queryNotSelf"
          >搜索</el-button
          >
        </div>
        <div class="d-flex flex-wrap-y member-scroll">
          <div class="d-flex flex-column">
              <el-radio-group v-model="selectEmployee">
                <el-radio v-model="item.userId"   class="mr30 mb30"
                          style="margin-left: 0"  :label="item.userId" :key="index" v-for="(item, index) in employees" >{{ item.name }}</el-radio>
              </el-radio-group>
          </div>

        </div>
        <div class="d-flex justify-content-center align-items-center">
          <p v-if="employees.length == 0 ">暂无员工</p>

          <el-button
                  class="v-primary ml10"
                  v-else
                  @click="administratorVisible = false"
                  type="primary"
            >取消</el-button >
          <el-button
                  class="v-primary ml10"
                  v-if="employees.length > 0 "
                  @click="employeeConfirm"
                  type="primary"
          >确认</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  fetchProductsTypeList,
  queryPageManager,
  deletedManager,
  changeBoss,
  checkSms,
  queryNotSelf
} from "@/api/companyManage.js";
import api from "@/api/login";
import { queryCompanyStaff } from "@/api/articleManagement";
import { getDataTimeSec } from "@/utils";
import Path from '@antv/g2/lib/geometry/path';
import utils from "@/utils/index";
import userRoot from '@/utils/root'
import { mapState } from 'vuex';

export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      keyword:'',
      optionList: "", //全部分类
      tableData: [],
      staffList:[],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      checkVisible:false,//新增部门开关
      bossCertificationVisible:false,
      administratorVisible:false,
      administratorKey:"",//员工姓名
      memberData:'',//成员数据
      selectEmployee:'',
      treeData: [],
      employees:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      countDown:0,
      certificationForm:{
        phone:'',
        smsCode:''
      }

    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.some(item => ['02100'].includes(item))//部门管理(所有)
      if(this.jurisdiction){
        this.getPageDepartment()
      }else{
        this.getPageDepartment()
      }
    }
  },

  mounted() {
    console.log('userRoot',userRoot)
    this.traverse(this.treeData)
    this.getPageDepartment()
  },
  methods: {
    // //请求管理员信息
    getPageDepartment() {

      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name:this.keyword,
        userId:this.jurisdiction ? this.$store.state.loginRoot.userInfo.userId : ''
      };
      queryPageManager(data)
      .then((res) => {
        this.loading = false;
        this.tableData = res.data.pageInfo.list || "";
        this.total = res.data.pageInfo.total;
        console.log(this.tableData)
      })
      .catch((err) => {
        this.tableData = [];
        this.loading = false;
        console.log(err);
      });
    },
    //请求预约数据
    // getTableData() {
    //   this.loading = true;
    //   let data = {
    //     pageNum: this.pageNum,
    //     pageSize: this.pageSize,
    //   };
    //   queryCompanyStaff({ companyId: 0 })
    //   .then((res) => {
    //     console.log(res)
    //     this.loading = false;
    //     this.tableData = res.data.pageInfo.list || "";
    //     this.total = res.data.pageInfo.total;
    //     console.log('this.tableData',this.tableData)
    //   })
    //   .catch((err) => {
    //     this.loading = false;
    //     this.tableData = [];
    //     console.log(err);
    //   });
    // },

    traverse(obj) {
      let arr = []
        for (var a in obj) {
          if (obj[a].children) {
            this.traverse(obj[a]); //递归遍历
          } else {
            if(obj[a].isSelect){
              arr.push(obj[a].id)
            }

          }
        }
        console.log(arr)
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看所有
    checkAll(lsitData){

      let than = this
      let data = JSON.parse(JSON.stringify(userRoot.userRoot))
      if(!lsitData.routers){
        return this.$message({
          message: "暂未分配权限",
          type: "warning",
        });
      }
      data.map((item,index) => {
        item.disabled = true

        if(item.children){

            item.children.map((v,k)=>{
              v.disabled = true
              if(v.children){
                v.children.map((v1,k1)=>{
                  v1.disabled = true
                  if(v1.children){
                    v1.children.map((v2,k2)=>{
                      v2.disabled = true
                    })
                  }
                })
              }
            })
        }
      })
      this.treeData = data
      this.memberData = lsitData
      console.log(lsitData)
      lsitData.routers = JSON.parse(lsitData.routers)
      than.$nextTick(()=>{
        than.$refs.tree.setCheckedKeys(lsitData.routers)
      })
      this.checkVisible = true
    },
    //解散部门
    dissolve(id){
      this.$confirm("是否确认删除该管理员，删除后不可恢复。", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        let data = {
          empId:id
        }
        deletedManager(data)
        .then(res=>{
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getPageDepartment()
        })
      })
      .catch((err) => {});
    },
    //编辑成员
    editDepartment(type,item){
      if(type == 'add'){
        this.$router.push({path:'AdministratorDetail'})
      }else{
        let id = item.empId
        this.$router.push({path:'/AdministratorDetail?id=' + id})
      }
    },
    //更换boss
    changeBoss(){
      this.$confirm("更换的BOSS新账户必须为企业下的员工账户" +
              "BOSS账户更换后，老账户则自动成为普通成员，并且此操作不可撤回，请谨慎操作", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      }).then(() => {
          this.bossCertificationVisible = true;
      })
      .catch((err) => {});
    },
    //验证boss手机号
    certificationphone(){
      checkSms(this.certificationForm)
        .then((res) => {
          //表示认证成功
            if(res && res.code == 200){
              this.queryNotSelf();
            }
        })
        .catch((err) => {
          this.$message.error(err.message)
        });
    },
    queryNotSelf(){
      //查询员工
      queryNotSelf({xx:false,name:this.administratorKey}).then(data =>{
        if(data.code == 200 && data.data){

          this.employees = data.data;
          this.administratorVisible = true;
        }else{
          this.employees = ''
        }

      });
    },
    //更换boss
    employeeConfirm(){
      if(!this.selectEmployee){
        this.$message({
          title: "提示",
          message: "请选择员工",
          type: "warning",
        });
      }
      changeBoss({userId:this.selectEmployee}) .then((res) => {
        //表示认证成功
        if(res && res.code == 200){
          this.$message({
            title: "提示",
            message: "更换成功",
            type: "warning",
          });
          this.administratorVisible = false;
          utils.clearLoginInfo()
        }
      })
      .catch((err) => {
      });
    },
    //全选
    selectedAll(){
      let selectedAllState =  this.staffList.findIndex(target=>target.isSelect===false)==-1
      if(selectedAllState){
        this.staffList.forEach(item=>{
          item.isSelect = false
        })
      }else{
        this.staffList.forEach(item=>{
          item.isSelect = true
        })
      }
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },

    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    getSendCode(phone) {
      if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(phone)) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.countDown = 60;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
              .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
              .then((res) => {})
              .catch((err) => {
                console.log(err);
              });
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.member-box{
  padding: 5px 10px;
  background: $color-1;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
}
.more-box{
  padding: 5px 10px;
  background: #FD634E;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  border-radius: 3px;
}
.member-scroll{
  max-height: 300px;
  overflow-y: auto;
}
</style>
